<template>
  <button class="bookmark" @click="bookmark">
    <icon
      v-if="state.isFavorite"
      name="bookmark-narrow-active"
      class="bookmark-icon"
    ></icon>
    <icon
      v-else
      name="bookmark-narrow"
      class="bookmark-icon"
    ></icon>
  </button>
</template>

<script>
import { defineComponent, reactive } from "vue";
import http from "@/utils/http";
export default defineComponent({
  name: "NoticeListItem",
  components: {},
  props: ["lectureKey", "isFavorite"],
  emits: ["bookmark"],
  setup(props, context) {
    const state = reactive({
      isFavorite: props.isFavorite,
      loading: false,
    });

    const bookmark = () => {
      if(state.loading) {
        return;
      }
      state.loading = true;
      if(state.isFavorite) {
        state.isFavorite = false;
        http
          .delete("/api/lectures/" + props.lectureKey + "/favorites")
          .then((response) => {
            context.emit('bookmark', props.lectureKey, false);
            state.loading = false;
            return response;
          })
          .catch((error) => {
            console.log("エラー:", error.response.data.errors);
            state.isFavorite = true;
            state.loading = false;
            return error.response;
          });
      } else {
        state.isFavorite = true;
        http
          .post("/api/lectures/" + props.lectureKey + "/favorites")
          .then((response) => {
            context.emit('bookmark', props.lectureKey, true);
            state.loading = false;
            return response;
          })
          .catch((error) => {
            console.log("エラー:", error.response.data.errors);
            state.isFavorite = false;
            state.loading = false;
            return error.response;
          });
      }
    }

    return {
      state,
      bookmark,
    };
  },
});
</script>
<style lang="scss" scoped>
.bookmark {
  padding-left: 15px;
  cursor: pointer;
  .bookmark-icon {
    width: 19px;
    height: 27px;
  }
}
</style>