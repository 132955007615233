<template>
  <div class="page-action">
    <div class="page-action-nation">
      <span class="nation-title">Page {{ state.nowPage }}/{{ allPage }}</span>
      <div class="page-action-nation-button">
        <p class="prev-button" @click="prev">
          <icon name="swiper-button-prev-btn" class="prev-button-icon" />
        </p>
        <p class="next-button" @click="next">
          <icon name="swiper-button-next-btn" class="next-button-icon" />
        </p>
      </div>
    </div>
  </div>
  <div class="my-lecture-list">
    <article class="my-lecture-list-item" v-for="item in pageItem" :key="item.key">
      <MyLectureListItem :articleData="item" :linkTo="props.linkTo" :linkText="props.linkText" @bookmark="bookmark"/>
    </article>
  </div>
</template>

<script>
import "element-plus/dist/index.css";
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import MyLectureListItem from "./MyLectureListItem.vue";
export default defineComponent({
  name: "myLectureList",

  components: {
    MyLectureListItem,
  },
  props: ["lectureList", "linkTo", "linkText"],
  emits: ["bookmark"],
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      sortItem: [
        {
          value: "lesson",
          label: "Lesson",
        },
        {
          value: "recent",
          label: "Recent",
        },
        {
          value: "oldest",
          label: "Oldest",
        },
      ],
      sortItemValue: "lesson",
      nowPage: 1,
    });

    const pageItem = computed(() => {
      return props.lectureList.slice(
        state.nowPage * 8 - 8,
        state.nowPage * 8
      );
    })
    const allPage = computed(() => {
      return Math.ceil(props.lectureList.length / 8);
    })

    // 前のぺージ
    const prev = () => {
      if (state.nowPage > 1) {
        // document.getElementById("app").scrollIntoView();
        state.nowPage -= 1;
      }
    };

    // 次のぺージ
    const next = () => {
      if (state.nowPage < allPage.value) {
        // document.getElementById("app").scrollIntoView();
        state.nowPage += 1;
      }
    };

    const bookmark = (lectureKey, isFavorite) => {
      context.emit('bookmark', lectureKey, isFavorite);
    };

    return {
      props,
      store,
      state,
      pageItem,
      allPage,
      prev,
      next,
      bookmark,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
}
.page-action-nation {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: $black-50;
  @include mq(sm) {
    width: 100%;
    justify-content: space-between;
  }
  .nation-title {
    margin-right: 20px;
  }
  .page-action-nation-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    .prev-button-icon,
    .next-button-icon {
      width: 40px;
      height: 40px;
    }
    .prev-button {
      margin-right: 20px;
    }
  }
  &--bottm {
    justify-content: end;
    margin-top: 65px;
  }
}
.my-lecture-list {
  margin: 50px -20px 140px 0;
  display: flex;
  flex-wrap: wrap;
  @include mq(sm) {
    display: block;
  }
}
.my-lecture-list-item {
  width: calc(25% - 20px);
  height: 402px;
  margin: 0 20px 30px 0;
  position: relative;
  top: 0;
  transition: top 100ms;
  @include mq(sm) {
    width: auto;
    height: auto;
  }
  &:hover+.my-lecture-list-item+.my-lecture-list-item+.my-lecture-list-item+.my-lecture-list-item {
    top: 140px;
    @include mq(sm) {
      top: 0;
    }
  }
}
</style>