<template>
  <div class="mypage-note-item-div">
    <!-- PC -->
    <div class="note-item-pc">
      <div
        class="item-course"
        :class="isEnterprise ? 'item-course-enterprise' : ''"
      >
        <p v-if="note.course_name">
          {{ note.course_name }}
        </p>
      </div>

      <div
        class="item-detail"
        :class="isEnterprise ? 'item-detail-enterprise' : ''"
      >
        <div class="note">
          <div class="note-item-title">
            <div class="note-lesson-title">
              <span class="note-bread-crumb-text">
                {{ note.lecture_name }} > {{ note.chapter_name }}
              </span>

              <span class="title-text">{{ note.material_name }}</span>
            </div>

            <div class="operation">
              <icon
                name="note-edit-icon"
                hoverName="note-edit-active-icon"
                class="operation-icon"
                @click="showEditorDialogPc()"
              />

              <div class="splitLine" />

              <icon
                name="note-del-icon"
                hoverName="note-del-active-icon"
                class="operation-icon"
                @click="showDeleteMemoConfirm()"
              />
            </div>
          </div>

          <span class="note-content ql-preview">
            <QuillEditor
              :content="note.memo"
              :readOnly="true"
              :options="{
                modules: {
                  toolbar: [],
                },
              }"
              contentType="html"
            ></QuillEditor>
          </span>

          <div class="note-detail">
            <div class="note-detail-box">
              <div class="note-detail-box-item">
                <div class="note-detail-box-item-label">登録日</div>
                <div class="note-detail-box-item-colon">:&nbsp;</div>
                <div class="note-detail-box-item-value">{{ display.toDate(note.created_at) }}</div>
              </div>

              <div class="note-detail-box-item">
                <div class="note-detail-box-item-label">更新日</div>
                <div class="note-detail-box-item-colon">:&nbsp;</div>
                <div class="note-detail-box-item-value">{{ display.toDate(note.updated_at) }}</div>
              </div>

              <div class="note-detail-box-item">
                <div class="note-detail-box-item-label">公開状態</div>
                <div class="note-detail-box-item-colon">:&nbsp;</div>
                <div class="note-detail-box-item-value">{{ note.is_available_material ? '公開中' : '非公開中' }}</div>
              </div>
            </div>

            <div
              class="note-detail-btn"
              :class="{ 'disable-lesson-btn': !note.is_available_material }"
              @click="seeVideo(note.lecture_code, note.chapter_code, note.material_code)"
            >
              <div class="note-detail-btn-text">
                受講を開始する
              </div>
              <icon name="note-detail-btn" class="note-detail-btn-icon"></icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SP -->
    <div id="noteItemSp" class="note-item-sp">
      <div class="note-bread-crumb">
        <span class="note-bread-crumb-text">
          <template v-if="note.course_name">
            {{ note.course_name }} > {{ note.lecture_name }} > {{ note.chapter_name }}
          </template>
          <template v-else>
            {{ note.lecture_name }} > {{ note.chapter_name }}
          </template>
        </span>
      </div>
      <div class="note-item-title">
        <span class="title-text">{{ note.material_name }}</span>
      </div>
      <span
        :id="`noteContentSp${index}`"
        class="note-content ql-preview"
      >
        <QuillEditor
          :content="note.memo"
          :readOnly="true"
          :options="{
            modules: {
              toolbar: [],
            },
          }"
          contentType="html"
        ></QuillEditor>
      </span>
      <div class="note-footer">
        <div class="operation">
          <icon
            name="note-edit-icon"
            class="operation-icon"
            @click="showSpEdit()"
            style="cursor: pointer;"
          />
          <div class="splitLine" />
          <icon
            name="note-del-icon"
            class="operation-icon"
            @click="showDeleteMemoConfirm()"
            style="cursor: pointer;"
          />
        </div>
        <template v-if="viewState.isDisplayReadMore">
          <div
            :id="`readMoreSp${index}`"
            class="readMore"
            v-if="viewState.isHide"
            @click="changeIsHide"
            style="cursor: pointer;"
          >
            <span>read more</span><icon name="read-more"></icon>
          </div>
          <div
            :id="`readMoreSp${index}`"
            class="readMore"
            v-else
            @click="changeIsHide"
            style="cursor: pointer;"
          >
            <span>read less</span><icon name="read-less"></icon>
          </div>
        </template>
      </div>

      <div class="note-detail">
        <div class="note-detail-box">
          <div class="note-detail-box-item">
            <div class="note-detail-box-item-label">登録日</div>
            <div class="note-detail-box-item-colon">:&nbsp;</div>
            <div class="note-detail-box-item-value">{{ display.toDate(note.created_at) }}</div>
          </div>

          <div class="note-detail-box-item">
            <div class="note-detail-box-item-label">更新日</div>
            <div class="note-detail-box-item-colon">:&nbsp;</div>
            <div class="note-detail-box-item-value">{{ display.toDate(note.updated_at) }}</div>
          </div>

          <div class="note-detail-box-item">
            <div class="note-detail-box-item-label">公開状態</div>
            <div class="note-detail-box-item-colon">:&nbsp;</div>
            <div class="note-detail-box-item-value">{{ note.is_available_material ? '公開中' : '非公開中' }}</div>
          </div>
        </div>

        <div
          class="note-detail-btn"
          :class="{ 'disable-lesson-btn': !note.is_available_material }"
          @click="seeVideo(note.lecture_code, note.chapter_code, note.material_code)"
        >
          <div class="note-detail-btn-text">
            受講を開始する
          </div>
          <icon name="note-detail-btn" class="note-detail-btn-icon"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "element-plus/dist/index.css";
import display from "@/utils/display";
import { defineComponent, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "myPageNoteListItemViews",

  components: {
    QuillEditor,
  },
  props: ["note", "index", "isEnterprise"],
  emits: ["showEditorDialogPc", "showDeleteMemoConfirm", "showSpEdit"],
  setup(props, context) {
    const router = useRouter();
    const viewState = reactive({
      isHide: true,
      isDisplayReadMore: true
    });

    // method
    const seeVideo = () => {
      router.push(`/view/${props.note.lecture_code}/${props.note.chapter_code}/${props.note.material_code}`);
    };
    const showEditorDialogPc = () => {
      context.emit(
        "showEditorDialogPc",
        props.note.key,
        props.note.memo,
        props.note.material_code,
        props.note.marked_time
      );
    };
    const showDeleteMemoConfirm = () => {
      context.emit(
        "showDeleteMemoConfirm",
        props.note.key,
        props.note.material_code
      );
    };
    const showSpEdit = () => {
      context.emit(
        "showSpEdit",
        props.note.key,
        props.note.memo,
        props.note.material_code,
        props.note.marked_time
      );
    };
    const changeIsHide = () => {
      const noteContentSpDom = document.getElementById(`noteContentSp${props.index}`);
      if (viewState.isHide) {
        viewState.isHide = false;
        noteContentSpDom.classList.add('show-all');
        noteContentSpDom.classList.remove('show-partial');
      } else {
        noteContentSpDom.classList.add('show-partial');
        noteContentSpDom.classList.remove('show-all');
        viewState.isHide = true;
      }
    }
    
    onMounted(() => {
      const noteContentSpDom = document.getElementById(`noteContentSp${props.index}`);
      const memoHight = noteContentSpDom.scrollHeight;
      if (memoHight <= 90) {
        viewState.isDisplayReadMore = false;
        viewState.isHide = false;
        noteContentSpDom.classList.add('show-all');
      } else {
        viewState.isHide = true;
        viewState.isDisplayReadMore = true;
        noteContentSpDom.classList.add('show-partial');
      }
    });
    
    return {
      display,
      viewState,

      // method
      seeVideo,
      showEditorDialogPc,
      showDeleteMemoConfirm,
      showSpEdit,
      changeIsHide,
    };
  },
});
</script>

<style lang="scss">
.mypage-note-item-div {
  position: relative;
  border-top: 0.5px solid $black;
  padding: 30px 0;

  @include mq(md) {
    flex-direction: column;
  }

  @include mq(sm) {
    padding: 30px 0 30px;
  }

  .note-item-pc {
    display: flex;

    @include mq(sm) {
      display: none;
    }

    .item-course {
      width: 240px;
      margin-right: 80px;
      
      p {
        padding: 15px;
        text-align: center;
        border: 1px solid $black;

        @include mq(sm) {
          width: 200px;
          padding: 15pxx;
          font-size: 12px;
        }
      }
    }

    .item-course-enterprise {
      display: none;
    }

    .item-detail.item-detail-enterprise {
      .note {
        max-width: 1186px;
      }
      .note-detail {
        margin-left: 320px;
      }
    }

    .item-detail {
      flex-grow: 1;

      .note {
        max-width: 866px;
        position: relative;

        .note-item-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .note-lesson-title {
            display: flex;
            flex-direction: column;

            .note-bread-crumb-text {
              font-weight: 500;
              font-size: 12px;
              line-height: 30px;
              letter-spacing: 0.1em;
              color: rgba(89, 88, 87, 0.8);
            }

            .title-text {
              font-weight: 700;
              font-size: 20px;
              line-height: 30px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
          }

          .operation {
            min-width: 72px;
            display: flex;
            justify-content: space-between;

            .splitLine {
              width: 0px;
              height: 20px;
              border-right: 1px solid $black;
            }

            .operation-icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        .note-content {
          display: block;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: $dark-grey;
        }

        .editarea {
          height: 440px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .editor {
            max-width: 1136px;
            height: 300px;
            border: 1px solid $black;
            background: $white;
          }

          .editBtnDIV {
            height: 140px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .editCancel {
              width: 200px;
              height: 60px;
              margin-top: 30px;
              border: 1px solid $black;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;

              .editCancelText {
                font-weight: 700;
                font-size: 18px;
                line-height: 70px;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $dark-grey;
              }
            }

            .editSave {
              width: 200px;
              height: 60px;
              background: $black;
              margin-top: 30px;
              box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
              display: flex;
              justify-content: center;
              align-items: center;

              .editSaveText {
                font-weight: 700;
                font-size: 18px;
                line-height: 70px;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $white;
              }
            }
          }
        }
      }
      .note-detail {
        display: flex;
        margin-left: 0px;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .note-detail-box {
          max-width: 866px;
          display: flex;
          flex: 3;
          justify-content: flex-end;

          .note-detail-box-item {
            min-width: 213px;
            height: 60px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.1em;
            color: rgba(89, 88, 87, 0.8);
            position: relative;
            background: $grey;

            @include mq(md) {
              flex-direction: column;
            }

            .note-detail-box-item-value {
              font-weight: 700;
              color: $black;
            }

            &::before {
              position: absolute;
              left: 0px;
              top: 20px;
              height: 20px;
              border-right: 0.5px solid $black-50;
              content: "";
            }

            &:first-child {
              &::before {
                content: none;
              }
            }
          }
        }

        .note-detail-btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          justify-content: space-between;
          min-width: 227px;
          flex: 1;
          height: 60px;
          align-items: center;
          background: $black;
          position: relative;
          z-index: 2;

          .note-detail-btn-text {
            font-weight: 700;
            font-size: 18px;
            letter-spacing: 0.08em;
            color: $white;
            margin-left: 30px;
            z-index: 2;
          }

          .note-detail-btn-icon {
            width: 40px;
            height: 10px;
            margin-right: 15px;
            z-index: 2;
          }

          @keyframes note-detail-btn-keyframe {
            from {
              width: 0px;
            }
            to {
              width: 100%;
            }
          }

          &:hover {
            &::before {
              content: "";
              left: 0;
              height: 60px;
              position: absolute;
              background: $primary;
              z-index: 0;
              animation: note-detail-btn-keyframe 0.3s linear 0s 1 forwards;
            }

            &::after {
              content: "";
              left: 0;
              height: 60px;
              position: absolute;
              background: $black;
              z-index: 1;
              animation: note-detail-btn-keyframe 0.3s linear 0.6s 1 forwards;
            }
          }

          &.disable-lesson-btn {
            cursor: default;
            opacity: 0.3;
            pointer-events: none;
          }
        }
      }
    }
  }
  .note-item-sp {
    display: none;
    width: 100%;
    flex-direction: column;

    @include mq(sm) {
      display: flex;
    }

    .note-bread-crumb {
      margin: 0 0 10px 0;

      .note-bread-crumb-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: rgba(89, 88, 87, 0.8);
      }
    }
    .note-item-title {
      display: flex;
      flex-direction: row;
      position: relative;
      
      .title-text {
        margin: 0 0 15px 0;
        flex: 1;
        height: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $black;
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
      }
    }
    .note-content {
      margin: 0 0 15px 0;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      
      &.show-all {
        height: auto;
        overflow: auto;
        text-overflow: unset;
        display: block;

        .ql-blank {
          max-height: none;
        }
      }

      &.show-partial {
        .ql-blank {
          max-height: 90px;
          overflow: hidden;

          &::before {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 30px;
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, #fff 100%);
          }
        }
      }
    }

    .note-footer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .operation {
        min-width: 72px;
        display: flex;
        justify-content: space-between;

        .splitLine {
          width: 0px;
          height: 20px;
          border-right: 1px solid $black;
        }

        .operation-icon {
          width: 16px;
          height: 16px;
        }
      }
      .readMore {
        display: flex;
        align-items: center;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.1em;
          color: $secondary;
        }

        svg {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }

    .editarea {
      height: 100%;
      width: 100%;
      margin-left: -115px;
      margin-right: 115px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .editor {
        max-width: 1136px;
        height: 300px;
        border: 1px solid $black;
        background: $white;
      }

      .editBtnDIV {
        height: 140px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .editCancel {
          width: 200px;
          height: 60px;
          margin-top: 30px;
          border: 1px solid $black;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;

          .editCancelText {
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $dark-grey;
          }
        }

        .editSave {
          width: 200px;
          height: 60px;
          background: $black;
          margin-top: 30px;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;

          .editSaveText {
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $white;
          }
        }
      }
    }
    .note-detail {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .note-detail-box {
        height: 60px;
        max-width: 892px;
        display: flex;
        flex: 3;
        justify-content: flex-end;

        .note-detail-box-item {
          min-width: 100px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 60px;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.1em;
          color: rgba(89, 88, 87, 0.8);
          position: relative;
          background: $grey;

          .note-detail-box-item-label {
            margin-left: 0px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }

          .note-detail-box-item-colon {
            display: none;
          }

          .note-detail-box-item-value {
            margin-top: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }

          &::before {
            height: 15.5px;
            top: 22.5px;
            left: 0px;
            position: absolute;
            content: "";
            border-right: 0.5px solid $black-50;
          }

          &:first-child {
            &::before {
              content: none;
            }
          }
        }
      }

      .note-detail-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        min-width: 227px;
        height: 60px;
        align-items: center;
        background: $black;
        position: relative;
        z-index: 2;
        flex: 1;

        .note-detail-btn-text {
          margin-left: 30px;
          width: 96px;
          height: 18px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
          white-space: nowrap;
          z-index: 2;
        }

        .note-detail-btn-icon {
          margin-right: 20px;
          width: 40px;
          height: 10px;
          z-index: 2;
        }

        @keyframes note-detail-btn-keyframe {
          from {
            width: 0px;
          }
          to {
            width: 100%;
          }
        }

        &:hover {
          &::before {
            content: "";
            left: 0;
            height: 60px;
            position: absolute;
            background: $primary;
            z-index: 0;
            animation: unset;
          }

          &::after {
            content: "";
            left: 0;
            height: 60px;
            position: absolute;
            background: $black;
            z-index: 1;
            animation: unset;
          }
        }

        &.disable-lesson-btn {
          cursor: default;
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }

  .ql-preview {
    .ql-toolbar {
      display: none!important;
    }

    .ql-container {
      font-size: 16px;
      border: none;
    }

    .ql-editor {
      padding: 0;
    }
  }
}
</style>