<template>
  <common-block-title
    title="NOTIFICATION"
    sub-title="通知"
  />
  <div class="page-action">
    <div class="page-action-nation">
      <span class="nation-title"
        >Page {{ state.nowPage }}/{{ state.allPage }}</span
      >
      <div class="page-action-nation-button">
        <p class="prev-button" @click="prev">
          <icon
            name="swiper-button-prev-btn"
            class="prev-button-icon"
          />
        </p>
        <p class="next-button" @click="next">
          <icon
            name="swiper-button-next-btn"
            class="next-button-icon"
          />
        </p>
      </div>
    </div>
  </div>
  <div class="notice-list">
    <article
      class="notice-list-item"
      v-for="item in state.noticeItem"
      :key="item.key"
    >
      <notice-list-item :articleData="item" @read="read(item)" />
    </article>
  </div>
  <div class="page-action-nation page-action-nation--bottm">
    <span class="nation-title"
      >Page {{ state.nowPage }}/{{ state.allPage }}</span
    >
    <div class="page-action-nation-button">
      <p class="prev-button" @click="prev">
        <icon name="swiper-button-prev-btn" class="prev-button-icon" />
      </p>
      <p class="next-button" @click="next">
        <icon name="swiper-button-next-btn" class="next-button-icon" />
      </p>
    </div>
  </div>
</template>

<script>
import "element-plus/dist/index.css";
import http from "@/utils/http";
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import NoticeListItem from "@/views/mypage/notice/components/NoticeListItem.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
export default defineComponent({
  name: "noticeViews",

  components: {
    NoticeListItem,
    CommonBlockTitle,
  },
  props: ["articleData"],
  setup() {
    const store = useStore();
    const state = reactive({
      apiData: {},
      noticeItem: {},
      allPage: "",
      nowPage: 1,
    });

    // api 取得
    http
      .get("/api/notices?order=1")
      .then((res) => {
        state.apiData = res.data;
        console.log("api取得", state.apiData);
        state.noticeItem = state.apiData.slice(0, 10);
        state.allPage = Math.ceil(res.data.length / 10);
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

    // 前のぺージ
    const prev = () => {
      if (state.nowPage > 1) {
        document.getElementById("app").scrollIntoView();
        state.nowPage = state.nowPage - 1;
        state.noticeItem = state.apiData.slice(
          state.nowPage * 10 - 10,
          state.nowPage * 10
        );
      }
    };

    // 次のぺージ
    const next = () => {
      if (state.nowPage < state.allPage) {
        document.getElementById("app").scrollIntoView();
        state.nowPage = state.nowPage + 1;
        state.noticeItem = state.apiData.slice(
          state.nowPage * 10 - 10,
          state.nowPage * 10
        );
      }
    };

    // 既読
    const read = (item) => {
      item.is_read = 1;
      let allRead = state.apiData.every((element) => {
        return element.is_read;
      });
      if (allRead) {
        store.commit("setHasUnread", false);
      }
    };

    return {
      store,
      state,
      prev,
      next,
      read,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
}
.page-action-sort {
  font-size: 14px;
  font-weight: 700;
  color: $black-50;
  &::v-deep(.el-input) {
    width: 8em;
  }
  &::v-deep(.el-input__inner) {
    border: none;
    font-weight: 700;
    color: $primary;
  }
  &::v-deep(.el-icon-arrow-up) {
    &::before {
      color: $primary;
    }
  }
}
.page-action-nation {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: $black-50;
  @include mq(sm) {
    width: 100%;
    justify-content: space-between;
  }
  .nation-title {
    margin-right: 20px;
  }
  .page-action-nation-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    .prev-button-icon,
    .next-button-icon {
      width: 40px;
      height: 40px;
    }
    .prev-button {
      margin-right: 20px;
    }
  }
  &--bottm {
    justify-content: end;
    margin-top: 65px;
  }
}
.notice-list {
  margin-top: 50px;
}
.notice-list-item {
  display: flex;
  position: relative;
  border-top: 0.5px solid $black;
  padding: 30px 0;
  @include mq(md) {
    flex-direction: column;
  }
  @include mq(sm) {
    padding: 30px 0 50px;
  }
  &::before {
    content: "";
    width: 150px;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $black;
    transform: translateY(-50%);
    @include mq(sm) {
      width: 100px;
    }
  }
}
</style>