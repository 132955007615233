<template>
  <common-block-title
    title="Notes"
    sub-title="メモ"
  />
  <div class="page-action">
    <div class="page-action-nation">
      <span class="nation-title"
        >Page {{ viewState.nowPage }}/{{ viewState.allPage }}</span
      >
      <div class="page-action-nation-button">
        <p class="prev-button" @click="prev">
          <icon
            name="swiper-button-prev-btn"
            class="prev-button-icon"
          />
        </p>
        <p class="next-button" @click="next">
          <icon
            name="swiper-button-next-btn"
            class="next-button-icon"
          />
        </p>
      </div>
    </div>
  </div>
  <div
    v-if="viewState.isDisplayed"
    class="note-list"
  >
    <article
      v-for="(note, index) of viewState.notes"
      :key="note.key"
      class="note-list-item"
    >
      <noteListItem
        :note="note"
        :index="index"
        :isEnterprise="isEnterprise"
        @showEditorDialogPc="showEditorDialogPc"
        @showDeleteMemoConfirm="showDeleteMemoConfirm"
        @showSpEdit="showSpEdit"
      />
    </article>
  </div>
  <div class="page-action-nation page-action-nation--bottom">
    <span class="nation-title"
      >Page {{ viewState.nowPage }}/{{ viewState.allPage }}</span
    >
    <div class="page-action-nation-button">
      <p class="prev-button" @click="prev">
        <icon name="swiper-button-prev-btn" class="prev-button-icon" />
      </p>
      <p class="next-button" @click="next">
        <icon name="swiper-button-next-btn" class="next-button-icon" />
      </p>
    </div>
  </div>

  <!-- SP用操作ダイアログ -->
  <editorDialogSp
    v-show="viewState.isShowEditorDialogSp"
    ref="editorDialogSpRef"
    @editorText="editorText"
  ></editorDialogSp>
</template>

<script>
import "element-plus/dist/index.css";
import http from "@/utils/http";
import { defineComponent, reactive, onMounted, ref, nextTick, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import editorDialogSp from "@/views/mypage/note/components/EditorDialogSp.vue";
import noteListItem from "@/views/mypage/note/components/NoteListItem.vue";

export default defineComponent({
  name: "noteViews",

  components: {
    CommonBlockTitle,
    editorDialogSp,
    noteListItem,
  },
  emits: ["showEditorDialogPc", "showDeleteMemoConfirm"],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const isSp = store.state.isSp;
    const viewState = reactive({
      allNotes: {},
      notes: {},
      allPage: 1,
      nowPage: 1,
      isShowEditorDialogSp: false,
      response: {
        status: 0,
        data: {},
      },
      errors: [],
      isDisplayed: false,
    });
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const editorDialogSpRef = ref();

    // method
    const fetchMemos = async () => {
      return await http
        .get("/api/material-memos/me")
        .then((res) => {
          const memos = res.data;
          memos.forEach((item) => {
            // hideを補完
            item.hide = false;
          });
          viewState.allNotes = memos;
          
          console.log("api取得", viewState.allNotes);
          viewState.notes = viewState.allNotes.slice(0, 10);
          viewState.allPage = Math.ceil(res.data.length / 10);
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const saveEditNote = async (key, materialCode, markedTime, text) => {
      await http
        .put(
          `/api/materials/${materialCode}/memos/${key}?key_type=code`,
          {
            memo: text,
            marked_time: markedTime,
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      // 更新時はリストビューのキーが同じなので再描画されないので再描画する
      viewState.isDisplayed = false;
      await fetchMemos();
      viewState.isDisplayed = true;

      store.dispatch("calcIsEditorUsing", false);
    };
    const cancelEditNote = () => {
      store.dispatch("calcIsEditorUsing", false);
    };

    // 前のぺージ
    const prev = () => {
      if (viewState.nowPage > 1) {
        document.getElementById("app").scrollIntoView();
        viewState.nowPage = viewState.nowPage - 1;
        viewState.notes = viewState.allNotes.slice(
          viewState.nowPage * 10 - 10,
          viewState.nowPage * 10
        );
      }
    };

    // 次のぺージ
    const next = () => {
      if (viewState.nowPage < viewState.allPage) {
        document.getElementById("app").scrollIntoView();
        viewState.nowPage = viewState.nowPage + 1;
        viewState.notes = viewState.allNotes.slice(
          viewState.nowPage * 10 - 10,
          viewState.nowPage * 10
        );
      }
    };

    const showDeleteMemoConfirm = (key, materialCode) => {
      viewState.deleteMemoKey = key;
      viewState.deleteMaterialCode = materialCode;

      context.emit("showDeleteMemoConfirm");
    };
    const deleteMemo = async () => {
      await http
        .delete(
          `/api/materials/${viewState.deleteMaterialCode}/memos/${viewState.deleteMemoKey}?key_type=code`
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

        await fetchMemos();
    };
    const showEditorDialogPc = (key, content, materialCode, markedTime) => {
      context.emit(
        "showEditorDialogPc",
        key,
        content,
        materialCode,
        markedTime
      );
    };
    const showSpEdit = (key, content, materialCode, markedTime) => {
      viewState.isShowEditorDialogSp = true;
      nextTick(() => {
        editorDialogSpRef.value.init(key, content, materialCode, markedTime);
      });
    };
    const editorText = async (key, materialCode, text, markedTime) => {
      await http
        .put(
          `/api/materials/${materialCode}/memos/${key}?key_type=code`,
          {
            memo: text,
            marked_time: markedTime,
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      // 更新時はリストビューのキーが同じなので再描画されないので再描画する
      viewState.isDisplayed = false;
      await fetchMemos();
      viewState.isDisplayed = true;
    };
    const seeVideo = (lectureCode, chapterCode, materialCode) => {
      router.push(`/view/${lectureCode}/${chapterCode}/${materialCode}`);
    };
    const newLine = (context) => {
      let note = context.replace(/\r?\n/g, "<br>");
      return note;
    };
    const clamp = (text) => {
      let content = text.slice(0, 31) + "…";
      return content;
    };
    
    onMounted(async () => {
      await fetchMemos();
      viewState.isDisplayed = true;
    });

    return {
      // display,
      store,
      viewState,
      isSp,
      editorDialogSpRef,
      isEnterprise,

      // method
      prev,
      next,
      fetchMemos,
      saveEditNote,
      cancelEditNote,
      showDeleteMemoConfirm,
      deleteMemo,
      showEditorDialogPc,
      showSpEdit,
      editorText,
      clamp,
      seeVideo,
      newLine,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
}
.page-action-sort {
  font-size: 14px;
  font-weight: 700;
  color: $black-50;
  &::v-deep(.el-input) {
    width: 8em;
  }
  &::v-deep(.el-input__inner) {
    border: none;
    font-weight: 700;
    color: $primary;
  }
  &::v-deep(.el-icon-arrow-up) {
    &::before {
      color: $primary;
    }
  }
}
.page-action-nation {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: $black-50;
  @include mq(sm) {
    width: 100%;
    justify-content: space-between;
  }
  .nation-title {
    margin-right: 20px;
  }
  .page-action-nation-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    .prev-button-icon,
    .next-button-icon {
      width: 40px;
      height: 40px;
    }
    .prev-button {
      margin-right: 20px;
    }
  }
  &--bottom {
    justify-content: end;
    margin-top: 65px;
  }
}
.note-list {
  margin-top: 50px;
}
</style>
