<template>
  <main>
    <content-page-header>
      <login-user-infomation />
    </content-page-header>
    <div class="content-body">
      <div>
        <tab tab-count="4" :tab-name="state.tabName" @tabClick="tabClick" />
      </div>
      <panel-body>
        <section v-if="state.tabBody === 0">
          <common-block-title
            title="受講中の講座"
            :count="state.duringLectures.length"
          />
          <my-lecture-list :lecture-list="state.duringLectures" link-to="view" link-text="受講を開始する"  @bookmark="bookmark" />
          <common-block-title
            title="受講済の講座"
            :count="state.finishLectures.length"
          />
          <my-lecture-list :lecture-list="state.finishLectures" link-to="lecture" link-text="講座詳細を見る"  @bookmark="bookmark" />
        </section>
        <section v-if="state.tabBody === 1">
          <common-block-title
            title="お気に入り講座"
            :count="state.favoriteLectures.length"
          />
          <my-lecture-list :lecture-list="state.favoriteLectures" link-to="lecture" link-text="講座詳細を見る"  @bookmark="bookmark" />
        </section>
        <section v-if="state.tabBody === 2">
          <note
            ref="noteRef"
            @showEditorDialogPc="showEditorDialogPc"
            @showDeleteMemoConfirm="showDeleteMemoConfirm"
          />
        </section>
        <section v-if="state.tabBody === 3">
          <notice />
        </section>
      </panel-body>
    </div>

    <!-- PCメモ編集ダイアログ -->
    <editorDialogPc
      v-show="state.isShowEditorDialogPc"
      ref="editorDialogPcRef"
      @editorText="editorText"
    ></editorDialogPc>

    <!-- メモ削除ダイアログ -->
    <deleteDialog
      :isShowAlert="state.isShowAlert"
      title="Delete Note"
      subTitle="メモ削除"
      :message="`本当にメモを削除しますか？\n一度削除すると、\nメモは元に戻りません。`"
      cancel="Cancel"
      submit="Delete Note"
      @cancelClick="cancelDeleteMemo"
      @submitClick="deleteMemo"
      @closeDialog="cancelDeleteMemo"
    />

  </main>
</template>

<script>
import "element-plus/dist/index.css";
import { defineComponent, onBeforeMount, reactive, watch, computed, onMounted, ref, nextTick } from "vue";
import { useStore } from "vuex";
import contentPageHeader from "@/components/contentPageHeader.vue";
import LoginUserInfomation from "@/components/loginUserInfomation.vue";
import MyLectureList from "@/components/MyLectureList.vue";
import Note from "@/views/mypage/note/Note.vue";
import Notice from "@/views/mypage/notice/Notice.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import Tab from "@/components/CommonTab/tab.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import editorDialogPc from "@/views/mypage/note/components/EditorDialogPc.vue";
import deleteDialog from "@/components/CommonDialog/CommonDialog.vue";
import http from "@/utils/http";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "mypageViews",

  components: {
    contentPageHeader,
    LoginUserInfomation,
    MyLectureList,
    Note,
    Notice,
    CommonBlockTitle,
    Tab,
    PanelBody,
    editorDialogPc,
    deleteDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      tabName: [
        {
          en: "LESSONS",
          ja: "レッスン",
          isActive: true,
        },
        {
          en: "FAVORITES",
          ja: "お気に入り",
          isActive: false,
        },
        {
          en: "Notes",
          ja: "メモ",
          isActive: false,
        },
        {
          en: "NOTIFICATION",
          ja: "通知",
          isActive: false,
        },
      ],
      tabBody: 0,
      duringLectures: [],
      finishLectures: [],
      favoriteLectures: [],
      isShowEditorDialogPc: false,
      isShowAlert: false,
    });
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const isLogin = computed(() => store.state.user.isLogin);

    const noteRef = ref();
    const editorDialogPcRef = ref();

    window.scrollTo(0, 0);

    console.log(store._state.data.notice);
    watch(
      () => store._state.data.notice,
      () => {
        state.tabName.forEach((e) => {
          e.isActive = false;
        });
        state.tabBody = 3;
        state.tabName[3].isActive = true;
        if (store._state.data.notice === 1) {
          store.dispatch("changeNoticeTab", 0);
        }
      }
    );

    onBeforeMount(() => {
      console.log(store.state.user.headerToNoticeTab);
      if (store.state.user.headerToNoticeTab) {
        state.tabBody = 3;
      }
    });

    const tabClick = (n) => {
      state.tabName.forEach((e) => {
        e.isActive = false;
      });
      state.tabName[n].isActive = true;
      state.tabBody = n;
    };

    // method
    // 受講中の講座 取得
    const fetchLecturesByDuring = async () => { 
      return http
        .get("/api/lectures?status=2&res_ptn=1&order=3&count=80")
        .then((res) => {
          state.duringLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const fetchEnterpriseLecturesByDuring = async () => { 
      return http
        .get("/api/enterprise-lectures?status=2&order=2&count=80")
        .then((res) => {
          state.duringLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    // 受講済の講座 取得
    const fetchLecturesByFinished = async () => { 
      return http
        .get("/api/lectures/?status=3&res_ptn=1&order=3&count=80")
        .then((res) => {
          state.finishLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const fetchEnterpriseLecturesByFinished = async () => { 
      return http
        .get("/api/enterprise-lectures/?status=3&order=2&count=80")
        .then((res) => {
          state.finishLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    // お気に入り講座 取得
    const fetchLecturesByFavorite = async () => { 
      return http
        .get("/api/lectures/?favorite=1&res_ptn=1&count=80")
        .then((res) => {
          state.favoriteLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const fetchEnterpriseLecturesByFavorite = async () => { 
      return http
        .get("/api/enterprise-lectures/?favorite=1&count=80")
        .then((res) => {
          state.favoriteLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };

    const bookmark = (lectureKey, isFavorite) => {
      state.duringLectures.forEach((lecture) => {
        if (lecture.key == lectureKey) {
          lecture.is_favorite = isFavorite;
        }
      });
      state.finishLectures.forEach((lecture) => {
        if (lecture.key == lectureKey) {
          lecture.is_favorite = isFavorite;
        }
      });
      // 並び順不明なのでお気に入り取り直す
      http
        .get("/api/lectures/?favorite=1&res_ptn=1&count=80")
        .then((res) => {
          state.favoriteLectures = res.data;
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };

    const showEditorDialogPc = (key, content, materialCode, markedTime) => {
      state.isShowEditorDialogPc = true;
      nextTick(() => {
        editorDialogPcRef.value.init(key, content, materialCode, markedTime);
      });
    };
    const editorText = async (key, materialCode, text, markedTime) => {
      noteRef.value.editorText(key, materialCode, text, markedTime);
    };

    // メモ削除ダイアログ
    const showDeleteMemoConfirm = () => {
      if (!store.state.editorUsing) {
        state.isShowAlert = true;
      } else {
        return;
      }
    };
    const cancelDeleteMemo = () => {
      state.isShowAlert = false;
    };
    const deleteMemo = async () => {
      if (!store.state.editorUsing) {
        state.isShowAlert = false;
        await noteRef.value.deleteMemo();
      } else {
        return;
      }
    };

    const init = async () => {
      if (isEnterprise.value) {
        await fetchEnterpriseLecturesByDuring();
        await fetchEnterpriseLecturesByFinished();
        await fetchEnterpriseLecturesByFavorite();
      } else {
        await fetchLecturesByDuring();
        await fetchLecturesByFinished();
        await fetchLecturesByFavorite();
      }
    };
    const reInit = async () => {
      console.log("re-init");
      await init();
    };

    onMounted(async () => {
      if (!isLogin.value) {
        router.push("/");
        return;
      }

      if (store.state.user.servicePlanId === 0) {
        // サーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }

      await init();
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (!isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      noteRef,
      editorDialogPcRef,
      tabClick,
      bookmark,
      showEditorDialogPc,
      editorText,
      showDeleteMemoConfirm,
      cancelDeleteMemo,
      deleteMemo,
    };
  },
});
</script>

<style lang="scss" scoped>
.content-body {
  position: relative;
  margin-top: -221px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -180px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>