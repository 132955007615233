<template>
  <el-dialog
    v-model="viewState.isShowDialog"
    :lock-scroll="true"
    fullscreen
    width="100%"
    top="0"
    append-to-body
    :show-close="false"
    :destroy-on-close="false"
    :modal="false"
    custom-class="editor-dialog"
  >
    <div class="editDIV showEdit">
      <div class="editHeader">
        <span class="time" :wordCnt="viewState.inputWordCnt">{{
          display.toMemoTime(videoStore.currentTime)
        }}</span>
        <div class="btns">
          <div class="closeBtn" @click="closeBtnClick">
            <icon name="batu-icon" class="closeIcon" />
          </div>
          <div class="commitBtn" @click="commitBtnClick">
            <icon name="sp-editor-dialog-commit-btn" />
          </div>
        </div>
      </div>
      <div class="editorDIV">
        <QuillEditor
          ref="quillEditorRef"
          :toolbar="`#editorDialog-toolbar${memoKey}-doct`"
          contentType="html"
          placeholder="Write Note..."
          @textChange="textChange"
        >
          <template #toolbar>
            <div
              :id="`editorDialog-toolbar${memoKey}-doct`"
              class="editorDialog-toolbar"
            >
              <select class="ql-header">
                <option selected value="">Body</option>
                <option value="2">Title</option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-code-block"></button>
            </div>
          </template>
        </QuillEditor>
      </div>
      <div :class="{ saved: saved }" v-if="saved">
        <icon name="saved-icon" class="saveIcon" />
        <span class="savedText">SAVED</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ElDialog } from "element-plus";
import "video.js/dist/video-js.css";
import { defineComponent, reactive, ref, watch, nextTick } from "vue";
import { useGlobalStore } from "@/store/global";
import display from "@/utils/display";
export default defineComponent({
  props: {},
  setup(props, context) {
    const { videoStore } = useGlobalStore();

    const viewState = reactive({
      isShowDialog: false,
      saved: false,
      materialCode: "",
      markedTime: "",
      inputWordCnt: 0,
    });

    const quillEditorRef = ref();

    const init = (memoKey, text, materialCode, markedTime) => {
      viewState.isShowDialog = true;

      nextTick(() => {
        viewState.memoKey = memoKey;
        viewState.materialCode = materialCode;
        viewState.markedTime = markedTime;
        quillEditorRef.value.getQuill().root.innerHTML = text;
      });
    };
    const textChange = () => {
      viewState.inputWordCnt =
        quillEditorRef.value.getQuill().getText().length - 1;
    };
    const closeBtnClick = () => {
      viewState.isShowDialog = false;
    };
    const commitBtnClick = () => {
      viewState.saved = true;
      setTimeout(() => {
        viewState.isShowDialog = false;
        viewState.saved = false;
        context.emit(
          "editorText",
          viewState.memoKey,
          viewState.materialCode,
          quillEditorRef.value.getQuill().root.innerHTML,
          viewState.markedTime
        );
      }, 1000);
    };

    watch(
      () => viewState.inputWordCnt,
      (newVal) => {
        if (newVal > 1000) {
          quillEditorRef.value
            .getQuill()
            .deleteText(
              1000,
              newVal - 1000,
              quillEditorRef.value.getQuill().getText()
            );
        }
      }
    );

    return {
      videoStore,
      display,
      viewState,
      quillEditorRef,
      init,
      textChange,
      closeBtnClick,
      commitBtnClick,
    };
  },
  components: {
    QuillEditor,
    ElDialog,
  },
});
</script>

<style lang="scss">
.editor-dialog {
  .el-dialog__body {
    background: $white;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    height: 100%;
    padding: 0;
    overflow: hidden;
    .editDIV {
      position: absolute;
      right: 0px;
      width: 100%;
      .editHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-bottom: 0.5px solid rgba(15, 15, 17, 0.25);
        margin-left: 20px;
        .time {
          width: 76px;
          height: 36px;
          background: $grey;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $black;
          position: relative;
          &::after {
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.09em;
            color: $black;
            opacity: 0.6;
            content: attr(wordCnt) "/1000";
            position: absolute;
            width: 100%;
            left: 86px;
          }
        }
        .btns {
          display: flex;
          .closeBtn {
            width: 60px;
            height: 60px;
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            .closeIcon {
              width: 16px;
              height: 16px;
            }
          }
          .commitBtn {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .editorDIV {
        .ql-snow {
          border: none;
        }

        .editorDialog-toolbar {
          display: flex;
          justify-content: space-between;
          .ql-size {
            width: 80px;
            &::before {
              font-weight: 500;
              font-size: 16px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $dark-grey;
              opacity: 0.6;
            }
          }
          .ql-picker {
            display: inline-block !important;
          }
          border-bottom: 0.5px solid rgba(15, 15, 17, 0.25);
        }

        .ql-editor {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: 0.1em;
          color: $dark-grey;
          padding: 15px 11px 0 22px;
          // 滚动条整体宽度
          &::-webkit-scrollbar {
            width: 5px;
            height: 20px;
          }
          &::-webkit-scrollbar-track {
            background: $white;
          }
          &::-webkit-scrollbar-thumb {
            background: $primary;
            box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
            border-radius: 2px;
          }
          height: calc(100vh - 125px);
        }
        .ql-editor.ql-blank::before {
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $dark-grey;
          opacity: 0.2;
        }
        .ql-toolbar.ql-snow {
          padding: 20px;
        }
        .ql-snow .ql-picker-label {
          padding-left: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $dark-grey;
          opacity: 0.6;
        }
      }
      .saved {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 350px;
        height: 80px;
        // margin: auto;
        background: $secondary;
        box-shadow: 0px 20px 30px rgba(2, 218, 166, 0.25);
        z-index: 2;
        animation: saved 1s ease-out 1 forwards;
        .saveIcon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        .savedText {
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          /* identical to box height, or 187% */
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $white;
        }
      }
      @keyframes saved {
        100% {
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }
}
</style>
