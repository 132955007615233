<template>
  <article class="inner">
    <div class="image-area">
      <img :src="articleData.image_url" alt="" class="image" />
      <div class="course-type course1" v-if="!isEnterprise">
        {{ articleData.job_roles[articleData.job_roles.length - 1].name }}
      </div>
      <div class="image-footer">
        <div class="dark-mask">
          <div class="memo-count">メモ: {{ articleData.memos_count }}件</div>
          <ListBookmarkButton
            :lecture-key="state.lectureKey"
            :is-favorite="state.isFavorite"
            @bookmark="bookmark"
          />
        </div>
        <div class="light-mask">
          <div
            class="meter"
            :style="{ width: articleData.progress_rate + '%' }"
          ></div>
        </div>
      </div>
    </div>
    <div class="title-area">
      <h1 class="title">{{ articleData.name }}</h1>
    </div>
    <div class="memo-area">
      <template v-if="articleData.memos_count">
        <div class="time">{{ time }}</div>
        <div class="memo">{{ memo }}</div>
      </template>
      <div v-else class="no-memo">No Notes</div>
    </div>
    <button class="button" @click="click">
      <span class="button-text">{{ state.linkText }}</span>
      <icon name="chapter-detail-btn" class="chapter-detail-btn"></icon>
    </button>
  </article>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import util from "@/utils/util";
import display from "@/utils/display";
import ListBookmarkButton from "./listBookmarkButton.vue";
export default defineComponent({
  name: "MyLectureListItem",
  components: { ListBookmarkButton },
  props: ["articleData", "linkTo", "linkText"],
  emits: ["bookmark"],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      memos: props.articleData.material_memos
        .concat(),
      linkText: props.linkText,
      lectureKey: props.articleData.key,
      isFavorite: props.articleData.is_favorite,
    });

    const isEnterprise = computed(() => store.state.user.isEnterprise);

    const time = computed(() => {
      return state.memos.length
        ? display.toMemoTime(state.memos[0].marked_time)
        : "";
    });

    const memo = computed(() => {
      return state.memos.length ? util.stripTags(state.memos[0].memo) : "";
    });

    const click = () => {
      if (props.linkTo == "view") {
        router.push(
          "/view/" +
            props.articleData.code +
            "/" +
            props.articleData.next_attend_material.chapter_code +
            "/" +
            props.articleData.next_attend_material.code
        );
      } else if (props.linkTo == "lecture") {
        router.push("/lecture-detail/" + props.articleData.code);
      }
    };

    const bookmark = (lectureKey, isFavorite) => {
      context.emit("bookmark", lectureKey, isFavorite);
    };

    return {
      state,
      isEnterprise,
      time,
      memo,
      click,
      bookmark,
    };
  },
});
</script>
<style lang="scss" scoped>
.inner {
  height: 402px;
  overflow: hidden;
  transition: height 100ms;
  @include mq(sm) {
    height: auto;
  }
  .image-area {
    position: relative;
    background-position: center;
    background-size: cover;
    .image {
      width: 100%;
      height: 300px;
      object-fit: cover;
      @include mq(sm) {
        height: 180px;
      }
    }
    .course-type {
      position: absolute;
      width: 100%;
      top: 0;
      padding: 5px 20px;
      font-size: 12px;
      color: $white;
    }
    .course1 {
      background-color: $primary;
    }
    .course2 {
      background-color: $secondary;
    }
    .course3 {
      background-color: $black;
    }
    .course4 {
      background-color: $dark-grey;
    }
    .course5 {
      background-color: $grey;
    }
    .image-footer {
      position: absolute;
      width: 100%;
      bottom: 0;

      .dark-mask {
        padding: 17px 17px 17px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(15, 15, 17, 0.1);
        .memo-count {
          color: $white;
          height: 20px;
          flex: 1;
          border-right: 0.5px solid $white;
        }
      }
      .light-mask {
        height: 10px;
        background-color: rgba(255, 255, 255, 0.3);
        .meter {
          height: inherit;
          background-color: $secondary;
        }
      }
    }
  }
  .title-area {
    height: 100px;
    padding: 20px;
    border-bottom: 0.5px solid #000000;
    @include mq(sm) {
      height: auto;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.67;
      letter-spacing: 0.1em;
      color: $black;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .memo-area {
    display: flex;
    height: 80px;
    padding: 16px 20px;
    line-height: 1.4;
    font-size: 14px;
    @include mq(sm) {
      display: block;
      height: auto;
      padding: 30px 20px;
    }
    .time {
      flex-basis: 42px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      text-align: right;
      letter-spacing: 0.08em;
      margin-right: 16px;
      @include mq(sm) {
        text-align: left;
        margin-bottom: 20px;
      }
    }
    .memo {
      flex: 1;
      color: $dark-grey-80;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .no-memo {
      font-size: 14px;
      text-align: center;
      align-self: center;
      flex-grow: 1;
      letter-spacing: 0.09em;
      color: $grey;
    }
  }
  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 60px;
    padding: 20px 25px 20px 30px;
    background-color: $black;
    color: $white;
    .chapter-detail-btn {
      width: 40px;
      height: 10px;
    }
  }
  &:hover {
    height: 567px;
    @include mq(sm) {
      height: auto;
    }
    .image-area::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0.5px solid #000;
      pointer-events: none;
    }
  }
}
.item-content {
  width: 100%;
  overflow: hidden;
  .item-content-title {
    font-size: 20px;
    font-weight: 700;
    @include mq(sm) {
      margin-top: 20px;
      font-size: 16px;
    }
  }
  .item-content-paragraph {
    margin: 15px 0 0 0;
    @include mq(sm) {
      font-size: 14px;
    }
  }
  .item-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    @include mq(md) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 10px;
    }
  }
  .item-action-data {
    font-size: 12px;
    color: rgba(89, 88, 87, 0.8);
    @include mq(md) {
      margin-top: 30px;
    }

    .read-check {
      position: relative;
      border-right: 1px solid $black;
      padding: 0 30px 0 23px;
      &--not-yet {
        color: $primary;
        &::before {
          background-color: $primary;
          border: 1px solid $primary !important;
        }
      }
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 0;
        border: 1px solid rgba(89, 88, 87, 0.8);
        border-radius: 100%;
        transform: translate(00, -50%);
      }
    }

    .date {
      padding: 0 15px 0 30px;
    }
  }
  .item-action-control {
    color: $secondary;
    cursor: pointer;
    .read-action {
      margin: 0;
      .read-icon {
        img {
          vertical-align: baseline;
        }
        &--less {
          img {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
}
</style>
