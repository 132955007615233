<template>
  <div class="item-tag">
    <p
      class="tag-type tag-type--maintenance"
      v-if="articleData.notice_category_id == 1"
    >
      メンテナンス
    </p>
    <p
      class="tag-type tag-type--other"
      v-if="articleData.notice_category_id == 2"
    >
      その他
    </p>
    <p
      class="tag-type tag-type--new"
      v-if="articleData.notice_category_id == 3"
    >
      新着講座
    </p>
    <p
      class="tag-type tag-type--other"
      v-if="articleData.notice_category_id == 4"
    >
      お知らせ
    </p>
  </div>
  <div class="item-content">
    <p class="item-content-title">
      {{ articleData.title }}
    </p>
    <p v-if="state.readState == 'less'" class="item-content-paragraph">
      {{ clamp(articleData.content) }}
    </p>
    <p
      v-if="state.readState == 'more'"
      class="item-content-paragraph"
      v-html="newLine(articleData.content)"
    ></p>
    <div class="item-action">
      <div class="item-action-data">
        <span
          v-if="articleData.is_read == 0"
          class="read-check read-check--not-yet"
          >未読</span
        >
        <span v-if="articleData.is_read == 1" class="read-check">既読</span>
        <span class="date">{{ formatDate(articleData.start_at) }}</span>
      </div>
      <div class="item-action-control">
        <p class="read-action" @click="read(articleData.key)">
          <template v-if="state.readState == 'less'">
            read more
            <i class="read-icon read-icon--more"
              ><img src="@/assets/img/mypage-read-action.png" alt=""
            /></i>
          </template>
          <template v-if="state.readState == 'more'">
            read less
            <i class="read-icon read-icon--less"
              ><img src="@/assets/img/mypage-read-action.png" alt="" /></i
          ></template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";
import display from "@/utils/display";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "NoticeListItem",
  components: {},
  props: ["articleData"],
  emits: ["read"],
  setup(props, context) {
    const state = reactive({
      readState: "less",
    });

    // 取得したapiのtimestamp format
    const formatDate = (date) => {
      let timestamp = display.toNoticeTime(Date.parse(date));
      return timestamp;
    };

    // テキスト改行
    const newLine = (context) => {
      let note = context.replace(/\r?\n/g, "<br>");
      return note;
    };

    // もっと見る 見ない
    const read = (key) => {
      if (props.articleData.is_read != 1) {
        http
          .post(`/api/notices/${key}/read`)
          .then((res) => {
            if (res.status == 200) {
              context.emit("read");
            }
          })
          .catch((error) => {
            console.log("エラー:", error);
          });
      }
      if (state.readState == "less") {
        state.readState = "more";
      } else {
        state.readState = "less";
      }
    };

    const clamp = (text) => {
      let content = text.slice(0, 31) + "…";
      return content;
    };

    return {
      state,
      read,
      formatDate,
      clamp,
      newLine,
    };
  },
});
</script>
<style lang="scss" scoped>
.item-tag {
  min-width: 150px;
  max-width: 150px;
  margin-right: 120px;
  @include mq(sm) {
    min-width: 100px;
    max-width: 100px;
  }
  .tag-type {
    padding: 15px;
    background: $grey;
    text-align: center;
    @include mq(sm) {
      padding: 20px 5px;
      font-size: 12px;
    }
    &--maintenance {
      background-color: black;
      color: $white;
    }
    &--new {
      background-color: #02DAA6;
      color: #ffffff;
    }
  }
}
.item-content {
  width: 100%;
  overflow: hidden;
  .item-content-title {
    font-size: 20px;
    font-weight: 700;
    @include mq(sm) {
      margin-top: 20px;
      font-size: 16px;
    }
  }
  .item-content-paragraph {
    margin: 15px 0 0 0;
    @include mq(sm) {
      font-size: 14px;
    }
  }
  .item-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    @include mq(md) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 10px;
    }
  }
  .item-action-data {
    font-size: 12px;
    color: rgba(89, 88, 87, 0.8);
    @include mq(md) {
      margin-top: 30px;
    }

    .read-check {
      position: relative;
      border-right: 1px solid $black;
      padding: 0 30px 0 23px;
      &--not-yet {
        color: $primary;
        &::before {
          background-color: $primary;
          border: 1px solid $primary !important;
        }
      }
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 0;
        border: 1px solid rgba(89, 88, 87, 0.8);
        border-radius: 100%;
        transform: translate(00, -50%);
      }
    }

    .date {
      padding: 0 15px 0 30px;
    }
  }
  .item-action-control {
    color: $secondary;
    cursor: pointer;
    .read-action {
      margin: 0;
      .read-icon {
        img {
          vertical-align: baseline;
        }
        &--less {
          img {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
}
</style>
